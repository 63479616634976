import React from "react"
import {Link} from "react-router-dom"
import PropTypes from "prop-types"

import { ROUTE_PATHS } from "../utils/routes"

export const QuestionsAndContactComponent = ({showHeaderRow,isHomePage}) => {
  return (
    <>
    { showHeaderRow && <hr className="mt-5"/>}
    <p className={isHomePage ? "left-aligned" : "centered"}>Questions before you continue? Find answers <Link to={ROUTE_PATHS.FAQ}>here</Link>  or <Link to={ROUTE_PATHS.CONTACT}>contact us</Link>.</p>
    </>
  )
}


QuestionsAndContactComponent.propTypes = {
  showHeaderRow: PropTypes.bool,
  isHomePage: PropTypes.bool
}
