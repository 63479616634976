import React, { Component } from "react"
import { BrowserRouter, Route, Switch } from "react-router-dom"
import { ROUTE_PATHS } from "./utils/routes"

import {ScrollToTopComponent, CcpaComponent} from "./components"
import {LayoutComponent, } from "./components/layout"
import {
  Contact,
  Home,
  Tracker,
  Promotion,
  Register,
  StatusDetail,
  Thanks,
  Upload,
  Faq,
  ContactSuccess,
  NotFound,
  AuthenticatedRoute,
  SubmissionMethod,
  Lookup,
  ExlusiveCode,
} from "./pages"
import {AppProvider} from "./components/context/AppContext"

class App extends Component {
  render() {
    return (
      <div className="App">
        <AppProvider>
          <BrowserRouter>
            <ScrollToTopComponent>
              <CcpaComponent>
                <LayoutComponent>
                  <Switch>
                    <AuthenticatedRoute exact path={ROUTE_PATHS.PROMOTION} component={Promotion} />
                    <AuthenticatedRoute exact path={ROUTE_PATHS.REGISTER} component={Register} />
                    <AuthenticatedRoute exact path={ROUTE_PATHS.UPLOAD} component={Upload} />
                    <AuthenticatedRoute exact path={ROUTE_PATHS.THANKS} component={Thanks} />
                    <AuthenticatedRoute exact path={ROUTE_PATHS.STATUS} component={StatusDetail} />
                    <AuthenticatedRoute exact path={ROUTE_PATHS.SUBMISSION_METHOD} component={SubmissionMethod} />
                    <AuthenticatedRoute exact path={ROUTE_PATHS.TRACKER} component={Tracker} />
                    <Route exact path={ROUTE_PATHS.HOME} component={Home} />
                    <Route exact path={ROUTE_PATHS.OFFERSEARCH} component={Home} />
                    <Route exact path={ROUTE_PATHS.ORDERSEARCH} component={Home} />
                    <Route exact path={ROUTE_PATHS.CONTACT} component={Contact} />
                    <Route exact path={ROUTE_PATHS.CONTACT_SUCCESS} component={ContactSuccess} />
                    <Route exact path={ROUTE_PATHS.FAQ} component={Faq} />
                    <Route exact path={ROUTE_PATHS.LOOKUP} component={Lookup} />
                    <Route exact path={ROUTE_PATHS.HOMEPARAMS} component={Home} />
                    <Route exact path={ROUTE_PATHS.EXLUSIVECODE} component={ExlusiveCode} />
                    <Route component={NotFound} />
                  </Switch>
                </LayoutComponent>
              </CcpaComponent>
            </ScrollToTopComponent>
          </BrowserRouter>
        </AppProvider>
      </div>
    )
  }
}

export default App
